export const deleteContributor = (
  baseURL: string,
  projectId: string,
  contributorId: string
) => {
  const payload = {
    contributorId,
    projectId,
  }

  return fetch(`${baseURL}/api/contributor`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
  }).then((res) => res.json())
}
